import React from "react";
import HistoryAppRegulationsArticle from "./HistoryAppRegulationsArticle";
import HistoryAppOtherRules from "./HistoryAppOtherRules";
import { regulations } from "staticContent/historyApp";

const {
  title,
  rules,
  otherRegulations: { text, path, name },
} = regulations;

const rulesList = rules.map((rule, index) => (
  <HistoryAppRegulationsArticle
    key={index}
    article={rule}
    articleNumber={index}
  />
));

const HistoryAppRegulations = () => (
  <>
    <section className="section hero policy">
      <h1 className="hero__banner-text">{title}</h1>
    </section>
    <section className="section">
      {rulesList}
      <HistoryAppOtherRules path={path} name={name} text={text} />
    </section>
  </>
);

export default HistoryAppRegulations;
