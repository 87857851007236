import React from "react";
import HistoryAppPolicyArticle from "./HistoryAppPolicyArticle";
import HistoryAppOtherRules from "./HistoryAppOtherRules";
import { policy } from "staticContent/historyApp";

const {
  pageTitle,
  policyNote,
  policyFullText,
  otherRegulations: { text, path, name },
} = policy;

const articles = policyFullText.map(article => (
  <HistoryAppPolicyArticle key={article.title} {...article} />
));

const HistoryAppPrivacy = () => (
  <>
    <section className="section hero policy">
      <h1 className="hero__banner-text">{pageTitle}</h1>
    </section>
    <section className="section">
      <p className="policy__note">{policyNote}</p>
      {articles}
      <HistoryAppOtherRules path={path} name={name} text={text} />
    </section>
  </>
);

export default HistoryAppPrivacy;
