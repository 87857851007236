import React from "react";
import HistoryAppNestedList from "./HistoryAppNestedList";

const HistoryAppRegulationsArticle = ({ article, articleNumber }) => (
  <article className="policy__article">
    <h2 className="policy__article-title">{`§ ${++articleNumber}`}</h2>
    {typeof article === "string" ? (
      <p className="policy__note--rule">{article}</p>
    ) : (
      <HistoryAppNestedList listItem={article} />
    )}
  </article>
);

export default HistoryAppRegulationsArticle;
