import React from "react";
import { Link } from "gatsby";

const HistoryAppOtherRules = ({ path, name, text: { partOne, partTwo } }) => (
  <p className="policy__note policy__note--link">
    <span>{partOne}</span>
    <Link to={path}>{name}</Link>
    <span>{partTwo}</span>
  </p>
);

export default HistoryAppOtherRules;
