import React from "react";

const policy = {
  pageTitle: "Polityka prywatności aplikacji E-Historie",
  policyNote:
    "Poniższa Polityka Prywatności określa zasady, na jakich przetwarzane są Państwa dane osobowe oraz omówienie Państwa podstawowych praw związanych z przetwarzaniem przez nas Państwa danych osobowych.",
  otherRegulations: {
    text: {
      partOne: "Sprawdź ",
      partTwo: " z aplikacji e-historie.",
    },
    path: "/zasady-i-warunki-e-historie",
    name: "zasady i warunki korzystania",
  },
  policyFullText: [
    {
      title: "§ 1 Postanowienia ogólne",
      paragraphs: [
        "Administratorem danych jest HyperView sp. z o. o., prowadząca działalność pod adresem: ul. Chorzowska 50, 44-100 Gliwice, o nadanym numerze identyfikacji podatkowej (NIP): 6342834160, o nadanym numerze REGON: 360225711, o nadanym numerze KRS: 0000537819. Ochrona danych odbywa się zgodnie z wymogami powszechnie obowiązujących przepisów prawa, a ich przechowywanie ma miejsce na zabezpieczonych serwerach.",
        "Na potrzeby lepszego odbioru Polityki Prywatności termin „Użytkownik” zastąpiony został określeniem „Ty”, „Administrator” – „My”. Termin „RODO” oznacza Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE. Ponadto ilekroć w niniejszym dokumencie mowa o Aplikacji należy przez to rozumieć aplikację mobilną e–historie.pl stworzoną przez HyperView sp. z o.o.",
        "Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych. W tym celu używany jest m.in. bezpieczny protokół szyfrowania komunikacji (SSL).",
        "Każda osoba, której dane dotyczą (jeżeli jesteśmy ich administratorem) ma prawo dostępu do danych, sprostowania, usunięcia lub ograniczenia przetwarzania, prawo sprzeciwu, prawo wniesienia skargi do organu nadzorczego.",
      ],
    },
    {
      title: "§ 2 Administrator Danych",
      paragraphs: [
        "Jeśli zdecydujesz się skorzystać z naszej Aplikacji, to zgadzasz się na zbieranie i wykorzystywanie informacji w odniesieniu do tej polityki. Dane, które zbieramy są wykorzystywane do zapewnienia i poprawy usług. Nie będziemy wykorzystywać ani udostępniać informacji o użytkowniku nikomu poza przypadkami opisanymi w niniejszej Polityce Prywatności.",
        "Usunięcie danych osobowych może nastąpić na skutek cofnięcia zgody bądź wniesienia prawnie dopuszczalnego sprzeciwu na przetwarzanie danych osobowych. Zastrzegamy sobie prawo do przetwarzania Twoich danych po cofnięciu zgody wyłącznie w zakresie na potrzeby dochodzenia ewentualnych roszczeń przed sądem lub jeżeli przepisy krajowe, unijne bądź prawa międzynarodowego obligują nas do retencji danych.",
        "Kontakt z osobą nadzorującą przetwarzanie danych osobowych w organizacji jest możliwy drogą elektroniczną pod adresem e-mail: it@hyperview.pl.",
      ],
    },
    {
      title: "§ 3 Gromadzenie i wykorzystywanie informacji",
      paragraphs: [
        "Nie zbieramy świadomie informacji pozwalających na identyfikację użytkownika (wyjątek: dane dziennika — patrz §4.).",
        "W Aplikacji wykorzystujemy usługi stron trzecich, które mogą takowe informacje gromadzić.",
        {
          text: "Link do polityki prywatności zewnętrznych dostawców usług wykorzystywanych przez aplikację:",
          apps: [
            <a href="https://www.google.com/policies/privacy/">
              Google Play Services
            </a>,
            <a href="https://www.mapbox.com/legal/privacy">Mapbox</a>,
          ],
        },
      ],
    },
    {
      title: "§ 4 Dane dziennika",
      paragraphs: [
        "Informujemy, że podczas korzystania z naszej Aplikacji, w przypadku wystąpienia błędu, zbieramy na telefonie użytkownika dane i informacje (za pośrednictwem produktów podmiotów trzecich) zwane danymi dziennika.",
        "Dane dziennika mogą zawierać takie informacje jak nazwa urządzenia, wersja systemu operacyjnego, bieżący stan Aplikacji podczas korzystania, czas i data korzystania z Aplikacji oraz inne statystyki.",
      ],
    },
    {
      title: "§ 5 Linki do innych stron",
      paragraphs: [
        "Aplikacja zawiera linki do innych stron. Jeśli klikniesz na link strony trzeciej, zostaniesz przekierowany na tę stronę. Należy pamiętać, że te zewnętrzne strony nie są przez nas obsługiwane. Dlatego zalecamy zapoznanie się z polityką prywatności tych witryn.",
        "Nie mamy kontroli i nie ponosimy odpowiedzialności za treść, politykę prywatności lub praktyki stosowane przez witryny lub usługi innych firm.",
      ],
    },
    {
      title:
        "§ 6 Informacje na temat ochrony prywatności osób poniżej 13 roku życia",
      paragraphs: [
        "Aplikacja została dopuszczona przez Międzynarodową Koalicję ds. Klasyfikacji Wiekowej (ang. International Age Rating Coalition — IARC) do użytku przez osoby powyżej 3 roku życia.",
        "Ze względu na ograniczone wykorzystywanie informacji znajdują tu zastosowanie standardowe procedury. Klasyfikacja do wglądu w witrynie sprzedawcy.",
      ],
    },
    {
      title: "§ 7 Zmiany w Polityce Prywatności",
      paragraphs: [
        "Możemy od czasu do czasu aktualizować naszą Politykę Prywatności. Dlatego zaleca się okresowe przeglądanie tej strony w celu zapoznania się z wszelkimi zmianami.",
        "Zastrzegamy sobie prawo do dowolnej zmiany niniejszej Polityki bez konieczności informowania o tym użytkowników.",
        "Wprowadzone zmiany wchodzą w życie w dniu publikacji.",
      ],
    },
  ],
};

const regulations = {
  title: "Zasady i warunki korzystania z aplikacji e-historie",
  otherRegulations: {
    text: {
      partOne: "Sprawdź ",
      partTwo: " aplikacji e-historie.",
    },
    path: "/polityka-prywatnosci-e-historie",
    name: "politykę prywatności",
  },
  rules: [
    "Pobieranie i/lub korzystanie z aplikacji powoduje automatyczne obowiązywanie niniejszych warunków. Z tego też powodu powinni Państwo upewnić się, że przeczytaliście je dokładnie przed użyciem aplikacji.",
    "Zabrania się kopiowania lub modyfikowania aplikacji, jakiejkolwiek jej części lub naszych znaków towarowych w jakikolwiek sposób. Nie wolno próbować wyodrębniać kodu źródłowego aplikacji, a także tłumaczyć aplikacji na inne języki lub tworzyć jej pochodnych wersji. Sama aplikacja oraz wszystkie znaki towarowe należą do HyperView Sp. z o.o. Materiały graficzne użyte w aplikacji są własnością Domu Współpracy Polsko-Niemieckiej. Aplikacja ta jest darmowa i została sfinansowana ze środków Ministerstwa Spraw Wewnętrznych Budowy i Ojczyzny. Teksty zawarte w aplikacji są autorstwa i własności Domu Współpracy Polsko–Niemieckiej.",
    "Firma HyperView Sp. z o.o. dokłada wszelkich starań, aby aplikacja była jak najbardziej użyteczna i efektywna. Z tego powodu, Firma zastrzega sobie prawo do wprowadzania zmian w aplikacji w dowolnym czasie i z dowolnego powodu.",
    "Aplikacja e–historie może przechowywać i przetwarzać dane analityczne (patrz Polityka Prywatności §4). To Państwo odpowiadają za bezpieczeństwo swojego telefonu i dostępu do aplikacji. Dlatego zalecamy, aby nie stosować jailbreak ani rootowania telefonu, czyli procesu usuwania ograniczeń i restrykcji oprogramowania nałożonych przez oficjalny system operacyjny Twojego urządzenia. Może to spowodować, że Twój telefon stanie się podatny na działanie złośliwego oprogramowania, wirusów lub złośliwych programów, a także naruszyć funkcje bezpieczeństwa Twojego telefonu i może oznaczać, że aplikacja e–historie nie będzie działać poprawnie lub w ogóle.",
    {
      text: "Aplikacja korzysta z usług stron trzecich, które deklarują swoje własne Warunki użytkowania. Link do Warunków Użytkowania usługodawców zewnętrznych używanych przez aplikację:",
      apps: [
        <a href="https://policies.google.com/terms">Google Play Services</a>,
        <a href="https://www.mapbox.com/legal/tos">Mapbox</a>,
      ],
    },
    "Należy mieć świadomość, że za pewne rzeczy Firma HyperView Sp. z o.o. nie ponosi odpowiedzialności. Niektóre funkcje aplikacji wymagają aktywnego połączenia z Internetem. Połączenie to może mieć postać sieci Wi-Fi lub być zapewnione przez operatora sieci komórkowej, ale Firma nie ponosi odpowiedzialności za to, że aplikacja nie będzie działać w pełnym zakresie, jeśli użytkownik nie będzie miał dostępu do sieci Wi-Fi i/lub wykorzysta przysługujący mu limit danych.",
    "Jeżeli chodzi o odpowiedzialność HyperView Sp. z o.o. za korzystanie przez użytkownika z aplikacji, podczas korzystania z aplikacji należy pamiętać, że chociaż dokładamy wszelkich starań, aby zapewnić jej aktualność i poprawność w każdym momencie, polegamy na osobach trzecich, które dostarczają nam informacji, abyśmy mogli je udostępnić użytkownikowi. Firma nie ponosi odpowiedzialności za jakiekolwiek straty, bezpośrednie lub pośrednie, poniesione przez użytkownika w wyniku całkowitego polegania na tej funkcjonalności aplikacji.",
    "W pewnym momencie może pojawić się potrzeba aktualizacji aplikacji. Aplikacja jest obecnie dostępna w systemie Android — wymagania dotyczące systemu (oraz wszelkich dodatkowych systemów, na które zdecydujemy się rozszerzyć dostępność aplikacji) mogą ulec zmianie, a użytkownik będzie musiał pobrać aktualizacje, chcąc nadal korzystać z aplikacji. HyperView Sp. z o.o. nie obiecuje, że zawsze będzie aktualizować aplikację w taki sposób, aby była ona aktualna dla użytkownika i/lub działała z wersją systemu Android, która została zainstalowana na urządzeniu użytkownika. Użytkownik zobowiązuje się jednak, że będzie zawsze akceptował aktualizacje aplikacji, gdy zostaną mu one zaproponowane.",
    "Możemy od czasu do czasu aktualizować nasz Regulamin. Dlatego zaleca się okresowe przeglądanie tej strony w celu zapoznania się z wszelkimi zmianami. Zastrzegamy sobie prawo do dowolnej zmiany niniejszego Regulaminu bez konieczności informowania o tym użytkowników. Wprowadzone zmiany wchodzą w życie w dniu publikacji.",
  ],
};

export { policy, regulations };
