import React from "react";
import HistoryAppNestedList from "./HistoryAppNestedList";

const HistoryAppPolicyArticle = ({ title, paragraphs }) => {
  const paragraphsList = paragraphs.map((paragraph, index) =>
    typeof paragraph === "string" ? (
      <li className="policy__article-list-item" key={index}>
        {paragraph}
      </li>
    ) : (
      <HistoryAppNestedList key={index} listItem={paragraph} />
    )
  );

  return (
    <article className="policy__article">
      <h2 className="policy__article-title">{title}</h2>
      <ol className="policy__article-list--ordered">{paragraphsList}</ol>
    </article>
  );
};

export default HistoryAppPolicyArticle;
